import React from 'react';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCategories } from './redux/categorySlice';
import TSSDesktopRoutes from './routes/TSSDesktopRoutes';
import TSSMobileRoutes from './routes/TSSMobileRoutes';
import RendezvousRoutes from './routes/RendezvousRoutes';
import OutdoorPrizesRoutes from './routes/OutdoorPrizesRoutes';

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1223px)' });

  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://ninja.sportssouth.dev';
  }

  const dispatch = useDispatch();

  useEffect(() => {
    axios.get('/products/taxonomy').then((res) => {
      dispatch(setCategories(res.data));
    });
  });

  if (window.location.host === 'theshootingwarehouse.com') {
    return (
      <div className='App'>
        {isDesktopOrLaptop && <TSSDesktopRoutes />}
        {isTabletOrMobile && <TSSMobileRoutes />}
      </div>
    );
  } else if (window.location.host === 'rendezvousonthered.com') {
    return <RendezvousRoutes />;
  } else if (window.location.host === 'outdoorprizes.com') {
    return <OutdoorPrizesRoutes />;
  } else {
    // This return is what is showing right now - feel free to change this on your local to view the other sites, just make sure you change it back to RendezvousRoutes before you push anything.
    return <RendezvousRoutes />;
  }
}

export default App;
